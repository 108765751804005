<template>
  <b-modal
    :id="id"
    size="lg"
    title="Chỉnh sửa"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreate($event)"
    @hidden="resetData"
  >
    <div>
      <b-row>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <b-form-group
            label-for="command"
          >
            <label for="command">Công tác chỉ đạo, ban hành kế hoạch triển khai, kiểm tra, đôn đốc thực hiện</label>
            <b-form-textarea
              v-model="dataInput.command"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <b-form-group
            label-for="launchingCeremony"
          >
            <label for="launchingCeremony">Lễ phát động Tháng hành động về an toàn, vệ sinh lao động (nếu có)</label>
            <b-form-textarea
              v-model="dataInput.launchingCeremony"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <b-form-group
            label-for="content"
          >
            <label for="content">Nội dung các hoạt động đã triển khai trong Tháng hành động</label>
            <b-form-textarea
              v-model="dataInput.content"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <b-form-group
            label-for="cost1"
          >
            <label for="cost1">Ngân sách nhà nước hoặc kinh phí của cơ sở sản xuất kinh doanh</label>
            <b-form-textarea
              v-model="dataInput.cost1"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <b-form-group
            label-for="cost2"
          >
            <label for="cost2">Ngân sách hỗ trợ, vận động từ doanh nghiệp và các nguồn hợp pháp khác</label>
            <b-form-textarea
              v-model="dataInput.cost2"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <b-form-group
            label-for="result"
          >
            <label for="result">Những kết quả đã đạt được; bài học kinh nghiệm</label>
            <b-form-textarea
              v-model="dataInput.result"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <b-form-group
            label-for="disadvantage"
          >
            <label for="disadvantage">Khó khăn, tồn tại</label>
            <b-form-textarea
              v-model="dataInput.disadvantage"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <b-form-group
            label-for="request"
          >
            <label for="request">Kiến nghị, đề xuất</label>
            <b-form-textarea
              v-model="dataInput.request"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        >
          <b-form-group
            label-for="request"
          >
            <label for="request">Ghi chú số vụ tai nạn trong quý II năm trước</label>
            <b-form-textarea
              v-model="dataInput.note"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BModal,
  },
  props: {
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    id: {
      type: String,
      default: '',
    },
    modeType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataInput: {
        reportMonthAccidentType: this.modeType,
        command: '',
        launchingCeremony: '',
        content: '',
        cost1: '',
        cost2: '',
        result: '',
        disadvantage: '',
        request: '',
        note: '',
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          reportMonthAccidentType: this.dataDetail.reportMonthAccidentType,
          command: this.dataDetail.command,
          launchingCeremony: this.dataDetail.launchingCeremony,
          content: this.dataDetail.content,
          cost1: this.dataDetail.cost1,
          cost2: this.dataDetail.cost2,
          result: this.dataDetail.result,
          disadvantage: this.dataDetail.disadvantage,
          request: this.dataDetail.request,
          note: this.dataDetail.note,
        }
      }
    },
  },
  methods: {
    handleCreate(bvModalEvt) {
      this.$emit('accept', this.dataInput)
    },
    resetData() {
      this.dataInput = {
        reportMonthAccidentType: this.modeType,
        command: '',
        launchingCeremony: '',
        content: '',
        cost: '',
        result: '',
        disadvantage: '',
        request: '',
        note: '',
      }
    },
  },
}
</script>
