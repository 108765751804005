export default {
  LIST_FINAL_REPORT: 'ReportMonthAccident/report-month-accidents',
  CREATE_EDIT_FINAL_REPORT: 'ReportMonthAccident/report-month-accident',
  DELETE_FINAL_REPORT: 'ReportMonthAccident/delete-report-month-accident',
  DETAIL_FINAL_REPORT: 'ReportMonthAccident/report-month-accident/',
  EXPORT_FINAL_REPORT: 'ReportMonthAccident/file-report-month-accident',
  COMBOBOX_TYPE_REPORT: 'ReportClassification/get-combobox-report-type',
  LIST_REPORT_MONTH_ACCIDENT_TITLES: 'ReportMonthAccident/list-report-month-accident-titles',
  CREATE_REPORT_MONTH_ACCIDENT_TITLES: 'ReportMonthAccident/update-report-month-accident-title',
  LIST_REPORT_MONTH_ACCIDENT_OTHER: 'ReportMonthAccident/list-report-month-accident-another',
  CREATE_REPORT_MONTH_ACCIDENT_OTHER: 'ReportMonthAccident/add-report-month-accident-another',
  DELETE_REPORT_MONTH_ACCIDENT_OTHER: 'ReportMonthAccident/delete-report-month-accident-another',
  EXPORT_EXCEL: 'ReportMonthAccident/export-excel',
}
