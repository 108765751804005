<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="doanh-nghiep"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <validation-observer ref="tabAccount">
      <TableListFinalReport
        v-if="isRendered('doanh-nghiep')"
        ref="business"
        :key="1"
        :modeType="'DepartmentOfEducationByBussiness'"
        :modeTypeView="'Bussiness'"
        class="tab-pane"
        :class="{'active':activeTab==='doanh-nghiep'}"
      />
      <TableListFinalReport
        v-if="isRendered('cap-phong')"
        :key="2"
        :modeType="'DepartmentOfEducationByEducation'"
        :modeTypeView="'EducationDepartment'"
        class="tab-pane"
        :class="{'active':activeTab==='cap-phong'}"
      />
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import TableListFinalReport from './components/TableListFinalReport.vue'

export default {
  components: {
    MyTabs,
    TableListFinalReport,
    ValidationObserver,
  },
  data() {
    return {
      activeTab: null,
      listTabs: [
        {
          key: 'doanh-nghiep',
          title: 'Báo cáo tổng kết tháng hành động về ATVSLĐ của doanh nghiệp ',
          icon: 'FileIcon',
          isRendered: false,
        },
        {
          key: 'cap-phong',
          title: 'Báo cáo tổng kết tháng hành động về ATVSLĐ của cấp phòng',
          icon: 'FileIcon',
          isRendered: false,
        },
      ],
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },
  created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'doanh-nghiep'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {
    // thay đổi active tab
    setActive(activeTab) {
      const tabNot = this.listTabs.find(x => x.key === this.activeTab)
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) {
        tab.isRendered = true
        tabNot.isRendered = false
      }
    },
    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
  },
}
</script>

<style lang="scss">
#modal-edit-report{
  .modal-dialog{
    max-width: 1126px;
  }
  .page-container{
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 0%),
  }
  .tab-container{
    margin-bottom: 0,
  }
}
</style>
